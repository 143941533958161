import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Formik, FormikProps } from 'formik';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import Checkbox from '../FormFields/Checkbox';
import Loading from '../Loading/index';
import RepositCard from '../Reposit/RepositCard';
import SecondaryPanel from '../SecondaryPanel';
import { P1, P2 } from '../Typography';

interface CheckoutFormProps {
  stripe?: any;
  warningMessage?: React.FC;
  isSubmitting: boolean;
  submitCard: (stripe: Stripe, elements: StripeElements) => void;
  buttonText?: string;
  redWarningMessage: boolean;
  showCheckbox: boolean;
}

const Inner = styled.div`
  padding: 0 36px;
`;

const WarningMessageContainer = styled(P2)<{ redWarningMessage: boolean }>`
  color: ${(props) => (props.redWarningMessage ? props.theme.colors.negative : props.theme.colors.body)};
  font-weight: bold;
  margin: 0 0 18px;
`;

const PaymentElementWrapper = styled.div`
  margin-bottom: 14px;
`;

const PanelWithColor = styled(SecondaryPanel)`
  margin-bottom: 24px;
`;

interface CheckoutFormValues {
  accept?: boolean;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  warningMessage: WarningMessage,
  isSubmitting,
  submitCard: submitPayment,
  buttonText,
  redWarningMessage,
  showCheckbox,
}) => {
  const [isPaymentElementReady, setPaymentElementReady] = React.useState(false);

  const stripe: Stripe | null = useStripe();
  // Casting as any as our TypeScript version is not recent enough to handle the latest version of the Stripe API
  const elements: any = useElements();
  // Stripe.js has not loaded yet.
  if (!stripe || !elements) {
    return <Loading />;
  }

  return (
    <Formik
      initialValues={{
        accept: WarningMessage ? false : undefined,
      }}
      onSubmit={async () => {
        const { error: submitError } = await elements.submit();
        if (submitError) throw submitError;
        submitPayment(stripe, elements);
      }}
    >
      {({ handleChange, handleSubmit, values }: FormikProps<CheckoutFormValues>) => {
        const isDisabled = WarningMessage && showCheckbox ? !values.accept || isSubmitting : isSubmitting;
        return (
          <form
            onSubmit={(e) => {
              if (isDisabled) return null;
              handleSubmit(e);
            }}
          >
            <RepositCard title="Payment Information" flush>
              <Inner>
                <P1>Please enter your payment details. Your card will be saved to your account for future payments.</P1>
                {isPaymentElementReady ? null : <Loading />}
                <PaymentElementWrapper>
                  <PaymentElement
                    onLoaderStart={() => setPaymentElementReady(true)}
                    options={{
                      wallets: {
                        applePay: 'never',
                        googlePay: 'never',
                      },
                    }}
                  />
                  <AddressElement options={{ mode: 'billing', display: { name: 'split' } }} />
                </PaymentElementWrapper>
              </Inner>
              <Fragment>
                {WarningMessage && (
                  <PanelWithColor style={{ background: redWarningMessage ? '#ffe6e6' : '' }}>
                    <WarningMessageContainer redWarningMessage={redWarningMessage}>
                      <WarningMessage />
                    </WarningMessageContainer>

                    {showCheckbox ? (
                      <Checkbox
                        name="accept"
                        label="I authorise payment to be taken"
                        isChecked={values.accept || false}
                        onChange={handleChange}
                      />
                    ) : null}
                  </PanelWithColor>
                )}
              </Fragment>
              <Inner style={{ paddingBottom: 24, paddingTop: 10 }}>
                <Button type="submit" disabled={isDisabled}>
                  {buttonText || `confirm and pay now`}
                </Button>
              </Inner>
            </RepositCard>
          </form>
        );
      }}
    </Formik>
  );
};

export default CheckoutForm;
