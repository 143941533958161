import React from 'react';
import styled from 'styled-components';

interface TextAreaProps {
  value?: string;
  name: string;
  error?: string;
  touched?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const StyledTextArea = styled.textarea<TextAreaProps>`
  background: #fff;
  border: 1px solid ${(props) => (props.error && props.touched ? props.theme.colors.negative : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  color: ${(props) => props.theme.colors.body};
  min-height: 180px;
  padding: 24px 30px;
  width: 100%;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  outline: none;
  max-width: 100%;

  &:focus {
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px ${(props) => props.theme.colors.body};
  }
`;

const TextArea: React.SFC<TextAreaProps> = ({ value, onChange, onBlur, name }) => {
  return <StyledTextArea value={value} onChange={onChange} onBlur={onBlur} name={name}></StyledTextArea>;
};

export default TextArea;
