import { ArbitrationDTO, ClaimDTO, PaymentPlanDTOStatusEnum } from '@reposit/api-client';
import { Location } from 'history';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import ImageIcon from '../../assets/svg/image.svg';
import Megaphone from '../../assets/svg/megaphone.svg';
import Button from '../../components/Button/index';
import { FullPageLoader } from '../../components/Loading/index';
import RepositCard from '../../components/RepositCard/index';
import SecondaryPanel from '../../components/SecondaryPanel/index';
import { Caption, Header2, Header4, P2 } from '../../components/Typography/index';
import { fetchArbitrationRequested } from '../../redux/arbitration/arbitration.actions';
import { fetchClaimRequested } from '../../redux/claim/claim.actions';
import { getArbitration, getClaimById } from '../../redux/claim/claim.selectors';
import { AppState } from '../../redux/root.reducer';
import { penceToPounds } from '../../utils/currency';
import ArbitrationOutcomePayment from '../ArbitrationOutcomePayment/ArbitrationOutcomePayment';
import { getPaymentPlanByClaimId } from '../../redux/payment-plan/payment-plan.selectors';
import { getCurrentOrderCustomer } from '../../redux/order/order.selectors';
import { Redirect } from 'react-router';

interface ArbitrationProps {
  location: Location<any>;
  // any - same as other containers
  match: any;
}

const Title = styled(Header2)`
  color: ${(props) => props.theme.colors.negative};
  margin: 24px 0 1rem;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ArbitrationHeader: React.FC<{ arbitratedAmount: number }> = ({ arbitratedAmount }) => (
  <Row>
    <Col lg={4} style={{ textAlign: 'center' }}>
      <img src={Megaphone} width="273" alt="megaphone" />
    </Col>
    <Col lg={8}>
      <Title>Payment required</Title>
      <Caption>
        The <BoldText>independent adjudicator</BoldText> has awarded £{penceToPounds(arbitratedAmount)} to your landlord
      </Caption>
      <Button
        buttonType="secondary"
        onClick={() => {
          scroller.scrollTo('arbitrationAction', {
            duration: 1000,
            smooth: true,
            offset: 0,
          });
        }}
      >
        Pay Now
      </Button>
    </Col>
  </Row>
);

const Inner = styled.div`
  align-items: center;
  display: flex;
`;

const Label = styled(P2)`
  margin: 0;

  span {
    color: ${(props) => props.theme.colors.primaryBrand};
    margin: 0 10px;
    text-decoration: underline;
  }
`;

const DocumentRow = styled.div`
  align-items: center;
  display: flex;
  margin: 16px 0 16px;

  &:before {
    background: transparent url(${ImageIcon}) center center no-repeat;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
  }

  p {
    margin: 0;
  }
`;

const Link = styled(P2)`
  a {
    color: inherit;
  }
`;

const ReportTitle = styled(Header4)`
  font-weight: bold;
  margin: 24px 0 12px;
`;

interface ArbitrationDetailsProps {
  arbitration: ArbitrationDTO;
  claim: ClaimDTO;
}

const getMessage = (claim: ClaimDTO, arbitration: ArbitrationDTO) => {
  const { finalAmount, limit } = claim;
  const adjudicationReduced = arbitration.arbitratedAmount < (finalAmount as number);
  const isArbBelowLimit = arbitration.arbitratedAmount < limit;
  if (adjudicationReduced && isArbBelowLimit) {
    return `The £60 dispute fee paid for the dispute has been credited against this amount as the adjudicator has not awarded wholly in the landlord's favour.`;
  } else if (adjudicationReduced && !isArbBelowLimit) {
    return `The £60 dispute fee paid for the dispute will not be credited against this amount, as 
    the report was awarded in the landlord’s favour with the total exceeding the amount you remain liable for (equivalent to 8 weeks rent).`;
  } else {
    return `The £60 dispute fee paid will not be credited against this amount as the adjudicator has awarded wholly in the landlord's favour.`;
  }
};

const ArbitrationDetails: React.FC<ArbitrationDetailsProps> = ({ claim, arbitration }) => {
  const adminFeeMessage = getMessage(claim, arbitration);
  return (
    <>
      <RepositCard
        tooltip={`The amount the independent adjudicator has awarded to your landlord.`}
        subtitle={`£${penceToPounds(arbitration.arbitratedAmount)}`}
        flush
        title="Result"
      >
        <SecondaryPanel>
          <Inner style={{ marginTop: 6 }}>
            <div>
              <Label>{adminFeeMessage}</Label>
              <div>
                <ReportTitle>Independent adjudication report</ReportTitle>
                <Label>Please click the document below to view the full adjudication report.</Label>
                <DocumentRow>
                  <Link style={{ display: 'inline' }}>
                    <a
                      href={arbitration.documents && arbitration.documents[0].document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {arbitration.documents && arbitration.documents[0].document.name}
                    </a>
                  </Link>
                </DocumentRow>
              </div>
            </div>
          </Inner>
        </SecondaryPanel>
      </RepositCard>
    </>
  );
};

export const Arbitration: React.FC<ArbitrationProps> = ({ match }) => {
  const { arbitrationId } = match.params;

  const arbitration = useSelector(getArbitration(arbitrationId));
  const hasArbitration = !!arbitration;
  const claimId = get(arbitration, 'claimId');
  const claim = useSelector((state: AppState) => getClaimById(state, claimId));
  const dispatch = useDispatch();
  const paymentPlan = useSelector(getPaymentPlanByClaimId(claimId));
  // OC is fetched in the payment compoennt rendered below
  const currentOrderCustomer = useSelector(getCurrentOrderCustomer);
  const hasPaymentPlan =
    paymentPlan &&
    currentOrderCustomer &&
    paymentPlan.orderCustomerId === currentOrderCustomer.id &&
    paymentPlan.status !== PaymentPlanDTOStatusEnum.DEFAULT &&
    paymentPlan.status !== PaymentPlanDTOStatusEnum.CANCELLED;
  useEffect(() => {
    dispatch(fetchArbitrationRequested(arbitrationId));
  }, [dispatch, arbitrationId]);

  useEffect(() => {
    if (hasArbitration && claimId) {
      dispatch(fetchClaimRequested(claimId));
    }
  }, [dispatch, hasArbitration, claimId]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!arbitration || !claim) return <FullPageLoader />;
  if (paymentPlan && hasPaymentPlan) return <Redirect to={`/payment-plan/${paymentPlan.id}`} />;

  return (
    <Container style={{ paddingTop: 36 }}>
      <ArbitrationHeader arbitratedAmount={arbitration.arbitratedAmount} />
      <ArbitrationDetails arbitration={arbitration} claim={claim} />
      {arbitration.repaymentOrderId && (
        <div className="arbitrationAction">
          <ArbitrationOutcomePayment orderId={arbitration.repaymentOrderId} claimId={claim.id} />
        </div>
      )}
    </Container>
  );
};
